<template>
  <div class="page page-box page-padding">
    <!-- 账号设置 -->
    <main class="page-main-main main-box" ref='main'>
      <!-- element 上传图片按钮 -->
      <h2>机构信息</h2>
      <h4>俱乐部图标</h4>
      <template>
        <div class="cropper-wrapper">
          <!-- element 上传图片按钮 -->
          <div v-if="!isPreview">
            <el-upload action="#" :show-file-list="false" :on-change="handleChangeUpload">
              <img v-if="userInfo.Logo" :src="`${userInfo.Logo}`" class="logo-box">
              <img v-else src="@/assets/defaultLogo.png" class="logo-box">
            </el-upload>
            <div class="imgFont">(支持JPG、JPEG、PNG格式图片，20M大小以内)</div>
          </div>
          <div class="pre-box" v-else>
            <el-upload action="#" :show-file-list="false" :on-change="handleChangeUpload">
              <img v-if="previewImg" :src="previewImg" alt="裁剪图片" class="logo-box" />
            </el-upload>
            <div class="imgFont">(支持JPG、JPEG、PNG格式图片，20M大小以内)</div>
          </div>
          <el-upload class="upload-demo" action="#" :show-file-list="false" :on-change="handleChangeUpload">
            <el-button type="primary" plain>上传</el-button>
          </el-upload>
          <!-- vueCropper 剪裁图片实现-->
          <el-dialog title="图片剪裁" :visible.sync="dialogVisible" class="crop-dialog" append-to-body :close-on-click-modal='false'>
            <div class="cropper-content">
              <div class="cropper" style="text-align: center">
                <vueCropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed" :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue" :fixedBox="option.fixedBox" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" @cropMoving="cropMoving" />
              </div>
            </div>
            <div class="action-box">
              <el-upload class="upload-demo" action="#" :show-file-list="false" :on-change="handleChangeUpload">
                <el-button type="primary" plain>更换图片</el-button>
              </el-upload>
              <el-button type="primary" plain @click="clearImgHandle">清除图片</el-button>
              <el-button type="primary" plain @click="rotateLeftHandle">左旋转</el-button>
              <el-button type="primary" plain @click="rotateRightHandle">右旋转</el-button>
              <el-button type="primary" plain @click="changeScaleHandle(1)">放大</el-button>
              <el-button type="primary" plain @click="changeScaleHandle(-1)">缩小</el-button>
              <el-button type="primary" plain @click="downloadHandle('blob')">下载</el-button>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="noSave">取 消</el-button>
              <el-button class="discoloration" v-preventReClick @click="finish" :loading="loading">确 认</el-button>
            </div>
          </el-dialog>
        </div>
      </template>
      <h4>俱乐部名称</h4>
      <div class="cropper-wrapper">
        <div class="name-box">
          {{userInfo.OrgName}}
        </div>
        <el-button @click="reviseOrgName()" class="discoloration">修改</el-button>
      </div>
      <!-- 修改名称 -->
      <el-dialog title="修改名称" :visible.sync="reviseVisible" width="30%" :close-on-click-modal='false'>
        <div class="saveRevise">
          <el-form :model="alterForm" :rules="rules" ref='alterForm'>
            <el-form-item prop="OrgName">
              <el-input v-model="alterForm.OrgName" placeholder="请输入俱乐部名称" show-word-limit maxlength="20" />
            </el-form-item>
          </el-form>
          <el-button v-preventReClick @click="updateOrgName()" class="discoloration">保存</el-button>
        </div>
      </el-dialog>
      <h2 class="topMagin">账号安全</h2>
      <h4>绑定手机号</h4>
      <div class="cropper-wrapper">
        <div class="name-box">
          {{userInfo.Phone.replace( /([0-9]{3})([0-9]{4})([0-9]{4})/,"$1****$3")}}
        </div>
        <el-button @click="revisePhone()" class="discoloration">更换</el-button>
      </div>
      <!-- 更换名称 -->
      <el-dialog title="修改绑定手机号" :visible.sync="testPhoneVisible" :close-on-click-modal='false' width="30%">
        <span>为保护账号安全，请使用{{userInfo.Phone.replace( /([0-9]{3})([0-9]{4})([0-9]{4})/,"$1****$3")}}验证身份</span>
        <div class="saveRevise">
          <div class="testPhone">
            <el-form :model="alterForm" :rules="rules" ref='alterForm'>
              <el-form-item prop="Code">
                <el-input v-model="alterForm.Code" placeholder="验证码" name="Code" tabindex="2" auto-complete="on" @keyup.enter.native="testPhone" />
              </el-form-item>
            </el-form>
            <el-button class="discoloration" v-if="status !== 2" type="primary" @click.native.prevent="getPassword">{{ status === 1 ?  '获取验证码' : '重新获取' }}</el-button>
            <el-button class="discoloration" v-else>{{ time }}秒</el-button>
          </div>
          <el-button type="primary" @click.native.prevent="testPhone">下一步</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改绑定手机号" :visible.sync="updatePhoneVisible" :close-on-click-modal='false' width="30%">
        <div class="saveRevise">
          <el-form :model="updatePhoneForm" :rules="updatePhoneFormRules" ref='updatePhoneForm'>
            <el-form-item prop="Phone">
              <el-input v-model="updatePhoneForm.Phone" placeholder="请输入新手机号码" class="revise-input" />
            </el-form-item>
            <div class="testPhone">
              <el-form-item prop="Code">
                <el-input v-model="updatePhoneForm.Code" placeholder="请输入验证码" class="revise-input" />
              </el-form-item>
              <el-button class="discoloration" v-if="statusAgin !== 2" type="primary" @click.native.prevent="getUpdatePhone">{{ statusAgin === 1 ?  '获取验证码' : '重新获取' }}</el-button>
              <el-button class="discoloration" v-else>{{ time }}秒</el-button>
            </div>
          </el-form>
          <el-button type="primary" @click="updatePhone()">确认更改</el-button>
        </div>
      </el-dialog>
      <h4 class="topMagin">账号密码</h4>
      <div class="cropper-wrapper">
        <div class="name-box">
          已设置，可通过账号密码登录
        </div>
        <el-button @click="revisePassword()" class="discoloration">更换</el-button>
      </div>
      <!-- 更换名称 -->
      <el-dialog title="修改密码" :visible.sync="testPasswordVisible" width="30%" :close-on-click-modal='false'>
        <span>为保护账号安全，请使用{{userInfo.Phone.replace( /([0-9]{3})([0-9]{4})([0-9]{4})/,"$1****$3")}}验证身份</span>
        <div class="saveRevise">
          <div class="testPhone">
            <el-form :model="alterForm" :rules="rules" ref='alterForm'>
              <el-form-item prop="Code">
                <el-input v-model="alterForm.Code" placeholder="请输入验证码" class="revise-input" />
              </el-form-item>
            </el-form>
            <el-button class="discoloration" v-if="statusPassword !== 2" type="primary" @click.native.prevent="getNewPassword">{{ statusPassword === 1 ?  '获取验证码' : '重新获取' }}</el-button>
            <el-button class="discoloration" v-else>{{ time }}秒</el-button>
          </div>
          <el-button type="primary" @click="testPassword()">下一步</el-button>
        </div>
      </el-dialog>
      <el-dialog title="修改密码" :visible.sync="updatePasswordVisible" width="30%" :close-on-click-modal='false'>
        <el-form :model="updatePasswordForm" :rules="updatePasswordFormRules" ref='updatePasswordForm'>
          <span>请输入新的登录密码</span>
          <el-form-item prop="Password" class="newPassword">
            <el-input v-model="updatePasswordForm.Password" ref="Password" show-password placeholder="请输入新密码" />
          </el-form-item>
          <span class="spanPassword">密码长度8-16字符，应包含大、小写字母和数字</span>
          <el-form-item prop="SurePassword" class="newPassword">
            <el-input v-model="updatePasswordForm.SurePassword" ref="Password" show-password placeholder="请确认新密码" />
          </el-form-item>
        </el-form>
        <div class="saveRevise">
          <el-button type="primary" @click="updatePassword()">确认更改</el-button>
        </div>
      </el-dialog>
    </main>
  </div>
</template>

<script>
import { sendPhoneCode, checkPhoneCode } from '@/service/auth.js';
import { updateOrgInfo } from "@/service/home";
import upload from '@/mixins/upload';
import { VueCropper } from 'vue-cropper';
import { mapState } from "vuex";
import md5 from 'js-md5';



export default {
  name: 'AccountSettings',
  mixins: [upload],
  computed: {
    ...mapState(['navbar', 'userInfo'])
  },
  components: {
    VueCropper
  },
  data() {

    const validateOrgName = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入机构名称'));
      }
      callback();
    }

    const validateCode = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入验证码'));
      }
      callback();
    }

    const validateSurePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.updatePasswordForm.Password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    const validatePassword = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        callback(new Error('请输入密码'));
      } else {
        let p = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/;
        if (p.test(value.trim())) {
          callback();
        }
        return callback(new Error('密码长度8-16字符，应包含大、小写字母和数字'))
      }
    };

    const validateUpdatePhone = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        callback(new Error('请输入手机号'));
      } else {
        let p = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (p.test(value.trim())) {
          callback();
        }
        return callback(new Error('请输入正确格式手机号'))
      }
    };

    const validateUpdateCode = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入验证码'));
      }
      callback();
    }

    return {
      status: 1, // 验证码状态
      statusAgin: 1,
      statusPassword: 1,
      time: 60, // 时间
      alterForm: {
        Logo: '',
        Phone: '',
        Code: '',
        OrgName: ''
      },
      updatePasswordForm: {
        Password: '',
        SurePassword: '',
      },
      updatePhoneForm: {
        Phone: '',
        Code: ''
      },
      updatePasswordFormRules: {
        Password: [{ required: true, validator: validatePassword, trigger: 'blur' }],
        SurePassword: [{ required: true, validator: validateSurePassword, trigger: 'blur' }]
      },
      updatePhoneFormRules: {
        Phone: [{ required: true, validator: validateUpdatePhone, trigger: 'blur' }],
        Code: [{ required: true, validator: validateUpdateCode, trigger: 'blur' }]
      },
      rules: {
        OrgName: [{ required: true, validator: validateOrgName, trigger: 'blur' }],
        Code: [{ required: true, validator: validateCode, trigger: 'blur' }]
      },
      // cosHttp: process.env.VUE_APP_COSHTTP,
      isPreview: false,
      dialogVisible: false,
      previewImg: '', // 预览图片地址
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loading: false,
      reviseVisible: false, // 俱乐部名称修改弹窗
      testPhoneVisible: false, // 绑定手机号修改-验证码弹窗
      updatePhoneVisible: false, // 绑定手机号修改-确认更改弹窗
      testPasswordVisible: false, // 账号密码更改-手机号验证弹窗
      updatePasswordVisible: false, // 账号密码更改-确认更改弹窗
    }
  },
  methods: {

    async updateOrgInfo() {
      try {
        let res = await this.$confirm('确定保存当前修改内容吗？', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确 定',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        });
        if (res !== 'confirm') return;
        await updateOrgInfo({
          Logo: this.alterForm.Logo || this.userInfo.Logo,
          Name: this.alterForm.OrgName || this.userInfo.OrgName,
          Password: this.updatePasswordForm.Password ? md5(this.updatePasswordForm.Password) : undefined,
          Phone: this.updatePhoneForm.Phone || this.userInfo.Phone
        });
        this.userInfo.Phone = this.updatePhoneForm.Phone || this.userInfo.Phone;
        this.userInfo.OrgName = this.alterForm.OrgName || this.userInfo.OrgName;
        this.$message({
          message: '保存成功',
          type: 'success',
          center: true,
        });
        this.manageVisible = false;
        this.reviseVisible = false;
        this.updatePhoneVisible = false;
        this.updatePasswordVisible = false;
        this.userInfo.Logo = this.alterForm.Logo;
        this.$store.commit('set_userInfo', this.userInfo);
      } catch (err) {
        this.isPreview = this.userInfo.Logo;
        this.previewImg = this.userInfo.Logo;
      }
    },

    // 正在上传中...
    uploading(progressData) {
      let index = this.fileList.length - 1;
      this.fileList[index].percentage = progressData.percent * 100;
      this.fileList[index].status = 'uploading';
    },

    // 上传按钮 限制图片大小和类型
    handleChangeUpload(file) {
      if (JSON.parse(JSON.stringify(file)).status === 'ready') {
        const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/jpg';
        const isLt20M = file.raw.size / 1024 / 1024 < 20;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!');
          return false;
        }
        if (!isLt20M) {
          this.$message.error('上传头像图片大小不能超过 20MB!');
          return false;
        }
        this.fileName = file.name.split('.')[0];
        // 上传成功后将图片地址赋值给裁剪框显示图片
        this.$nextTick(async () => {
          this.loading = true;
          // base64方式
          // this.option.img = await fileByBase64(file.raw)
          this.option.img = URL.createObjectURL(file.raw)
          this.loading = false;
          this.dialogVisible = true;
        })

      }
    },
    // 放大/缩小
    changeScaleHandle(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 左旋转
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRightHandle() {
      this.$refs.cropper.rotateRight();
    },
    // 下载
    downloadHandle(type) {
      let aLink = document.createElement('a');
      aLink.download = 'author-img';
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          let downImg = window.URL.createObjectURL(data);
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          let downImg = c;
          aLink.href = data;
          aLink.click();
        })
      }
    },
    // 清理图片
    clearImgHandle() {
      this.option.img = '';
    },
    // 截图框移动回调函数
    cropMoving(data) {
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
    },

    noSave() {
      this.dialogVisible = false;
    },
    async finish() {
      // 获取截图的 blob 数据
      this.$refs.cropper.getCropData((data) => {
        this.loading = true;
        this.dialogVisible = false;
        this.previewImg = data;
        this.isPreview = true;
        this.loading = false;

        let arr = data.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let suffix = mime.split('/')[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let file = new File([u8arr], `${this.fileName}.${suffix}`, {
          type: mime
        });
        this.uploadTecent(file).then(url => {
          this.alterForm.Logo = process.env.VUE_APP_COSHTTP + url;
        });
      })
      this.updateOrgInfo();
    },

    // 修改俱乐部名称
    reviseOrgName() {
      this.reviseVisible = true;
      this.alterForm.OrgName = this.userInfo.OrgName;
    },
    // 确认修改俱乐部名称
    updateOrgName() {
      this.$refs.alterForm.validate(async (valid) => {
        if (!valid) return;
        this.updateOrgInfo();
      })
    },

    // 更换绑定手机号
    revisePhone() {
      this.testPhoneVisible = true;
      this.alterForm.Code = '';
    },

    // 验证绑定手机号
    testPhone() {
      // 检验手机号和验证码
      this.$refs.alterForm.validate(async valid => {
        if (!valid) return;
        await checkPhoneCode({
          Phone: this.userInfo.Phone,
          Code: this.alterForm.Code,
        });
        this.testPhoneVisible = false;
        this.updatePhoneVisible = true;
        this.updatePhoneForm.Phone = '';
        this.updatePhoneForm.Code = '';
      });
    },
    // 确认更换绑定手机号
    updatePhone() {
      this.$refs.updatePhoneForm.validate(async valid => {
        if (!valid) return;
        let res = await checkPhoneCode({
          Phone: this.updatePhoneForm.Phone,
          Code: this.updatePhoneForm.Code,
        });
        this.updateOrgInfo();
      });
    },

    // 更换账号密码
    revisePassword() {
      this.testPasswordVisible = true;
      this.alterForm.Code = '';
    },
    // 验证手机号
    testPassword() {
      this.$refs.alterForm.validate(async valid => {
        if (!valid) return;
        await checkPhoneCode({
          Phone: this.userInfo.Phone,
          Code: this.alterForm.Code,
        });
        this.testPasswordVisible = false;
        this.updatePasswordVisible = true;
        this.updatePasswordForm.Password = '';
        this.updatePasswordForm.SurePassword = '';
      });
    },
    // 确认更改账号密码
    updatePassword() {
      this.$refs.updatePasswordForm.validate(async valid => {
        if (!valid) return;
        this.updateOrgInfo();
      });
    },

    /**
     * 获取验证码
     */
    async getPassword() {
      let ret = await sendPhoneCode({
        Phone: this.userInfo.Phone,
      });
      if (ret.Code === '1107') {
        this.$message.error(ret.Msg);
      } else {
        this.status = 2;
        let timer = setInterval(() => {
          this.time -= 1;
          if (this.time === 0) {
            this.status = 3;
            this.time = 60; // 重置时间
            clearInterval(timer);
          }
        }, 1000);
      }
    },

    /**
     * 获取验证码
     */
    async getUpdatePhone() {
      this.$refs.updatePhoneForm.validateField('Phone', async (e) => {
        if (!e) {
          let ret = await sendPhoneCode({
            Phone: this.updatePhoneForm.Phone,
          });
          if (ret.Code === '1107') {
            this.$message.error(ret.Msg);
          } else {
            this.statusAgin = 2;
            let timer = setInterval(() => {
              this.time -= 1;
              if (this.time === 0) {
                this.statusAgin = 3;
                this.time = 60; // 重置时间
                clearInterval(timer);
              }
            }, 1000);
          }
        }
      })
    },
    /**
     * 获取验证码
     */
    async getNewPassword() {
      let ret = await sendPhoneCode({
        Phone: this.userInfo.Phone,
      });
      if (ret.Code === '1107') {
        this.$message.error(ret.Msg);
      } else {
        this.statusPassword = 2;
        let timer = setInterval(() => {
          this.time -= 1;
          if (this.time === 0) {
            this.statusPassword = 3;
            this.time = 60; // 重置时间
            clearInterval(timer);
          }
        }, 1000);
      }
    },
  }
}
</script>

<style lang='scss' scoped>
.cropper-content {
  .cropper {
    width: auto;
    height: 350px;
  }
}
.action-box {
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 0;
  }
  margin-top: 24px;
}
/deep/.el-dialog__footer {
  padding-right: 24px !important;
}
.logo-box {
  border: 1px dashed #8fcc80;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  line-height: 64px;
  margin-bottom: 8px;
  display: inline-block;
  color: #6a757e;
}

.label-blod {
  font-weight: bold;
}

.cropper-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-box {
  padding: 24px 20%;
}
h2 {
  font-weight: bold;
  color: #21293a;
  margin-bottom: 24px;
  height: 22px !important;
  font-size: 14px;
}
h4 {
  margin-bottom: 10px;
  font-weight: 400;
  color: #21293a;
  height: 22px !important;
  font-size: 14px;
}
.name-box {
  font-size: 14px;
  color: #6a757e;
}
.topMagin {
  margin-top: 24px;
}
.saveRevise {
  .el-form {
    width: 100%;
  }
  .testPhone {
    width: 100%;
    display: flex;
    .el-form-item {
      width: 100%;
    }
    .discoloration {
      width: 120px;
      margin-top: 2px;
      margin-left: 10px;
    }
    margin: 16px 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.newPassword {
  margin-top: 8px;
}
.spanPassword {
  color: #bebebe;
}

.imgFont {
  margin-bottom: 8px;
}
/deep/.el-input .el-input__inner {
  height: 36px !important;
}
</style>
