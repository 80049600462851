import http from "@/utils/http";

/**
 * 获取首页统计-顶部统计
 */
export const getTeacherIndex = data => {
  return http.get("/club/getTeacherIndex", data);
};

/**
 * 获取首页统计-顶部统计
 */
export const getIndexCourseSum = data => {
  return http.get("/club/getIndexCourseSum", data);
};


/**
 * 获取navbar右侧登录管理信息
 */
export const updateOrgInfo = data => {
  return http.post("/club/updateOrgInfo", data);
};